var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "left-side" }, [
    _c("div", { staticClass: "left-side-wrapper" }, [
      _vm._m(0),
      _c("div", { staticClass: "stage-text" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.current_page == 1
                ? _vm.aboutProject
                : _vm.current_page == 2
                ? _vm.aboutStartup
                : _vm.getStarted
            ) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "stage-text-below" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.current_page == 2
                ? _vm.startupSubtext
                : _vm.current_page == 3
                ? _vm.getStartedText
                : ""
            ) +
            "\n    "
        ),
      ]),
      _c(
        "p",
        {
          staticClass: "blue-p dashbboard-mouse",
          on: {
            click: function ($event) {
              return _vm.backToDashboard()
            },
          },
        },
        [
          _c("img", {
            staticClass: "dashbboard-mouse",
            attrs: { src: "/img/onboarding/path.svg" },
          }),
          _vm._v("\n      Dashboard\n    "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("img", { attrs: { src: "/img/onboarding/get-started.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }