var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "MobileInformation",
        _vm._b(
          {},
          "MobileInformation",
          { current_page: _vm.current_page },
          false
        )
      ),
      _c("div", { staticClass: "form" }, [
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n        Product name\n        "),
            _vm.form_errors.project_name
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.project_name[0])),
                ])
              : _vm._e(),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.project_name,
                expression: "form.project_name",
              },
            ],
            staticClass: "form-control",
            attrs: {
              name: "project_name",
              placeholder: "Enter product name, e.g Uber",
              id: "project_name",
              type: "text",
            },
            domProps: { value: _vm.form.project_name },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "project_name", $event.target.value)
                },
                function ($event) {
                  _vm.form_errors.project_name = []
                },
              ],
            },
          }),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n        Product description\n        "),
            _vm.form_errors.overview
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.overview[0])),
                ])
              : _vm._e(),
          ]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.overview,
                expression: "form.overview",
              },
            ],
            staticClass: "form-control",
            staticStyle: { height: "unset" },
            attrs: {
              name: "overview",
              maxlength: "160",
              rows: "4",
              id: "textarea",
              placeholder: "Description of product, e.g Taxi's on Demand",
            },
            domProps: { value: _vm.form.overview },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "overview", $event.target.value)
                },
                function ($event) {
                  _vm.form_errors.overview = []
                },
              ],
              keyup: _vm.countCharacters,
            },
          }),
          _c(
            "div",
            {
              staticClass: "brief_counter",
              staticStyle: {
                "text-align": "left, font-size:10px",
                height: "13px",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCount,
                      expression: "showCount",
                    },
                  ],
                  staticStyle: { "text-align": "left" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.count) +
                      " | " +
                      _vm._s(_vm.max_length) +
                      "\n          "
                  ),
                  _c("span", { class: { brief_limit: _vm.isLimit } }, [
                    _vm._v("\n            Remaining: " + _vm._s(_vm.remainder)),
                  ]),
                ]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n        Product Platform\n        "),
            _vm.form_errors.project_type
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.project_type[0])),
                ])
              : _vm._e(),
          ]),
          _c("label", { staticClass: "label-description" }, [
            _vm._v("What platform is your product targeted at?"),
          ]),
          _c(
            "div",
            { staticClass: "list-wrapper" },
            _vm._l(_vm.platforms, function (item, index) {
              return _c("ChipButton", {
                key: index,
                attrs: {
                  text: item.name,
                  buttonIcon: item.img,
                  dashedBorder: true,
                  selected: _vm.checkedSelectedItem(item.tag, "platforms"),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleSelectPlatform(item.tag)
                  },
                },
              })
            }),
            1
          ),
        ]),
        _c("div", { staticClass: "form-grouped display-inline" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n        Product Industry\n        "),
            _vm.form_errors.industry
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.industry[0])),
                ])
              : _vm._e(),
          ]),
          _c("label", { staticClass: "label-description" }, [
            _vm._v("What industries does your product fit in?\n      "),
          ]),
          _c(
            "div",
            { staticClass: "list-wrapper" },
            _vm._l(_vm.projectIndustries, function (item, index) {
              return _c("ChipButton", {
                key: index,
                attrs: {
                  text: item.name,
                  dashedBorder: true,
                  buttonIcon: "img/interests/" + item.img,
                  selected: _vm.checkedSelectedItem(item.tag),
                },
                on: {
                  click: function ($event) {
                    return _vm.handleSelectIndustry(item.tag)
                  },
                },
              })
            }),
            1
          ),
        ]),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass: "grupa-blue-btn",
              on: { click: _vm.handleSubmitFormOne },
            },
            [_vm._v("\n        Proceed to Startup Info\n      ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("Tell us about your product")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }