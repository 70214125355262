var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "MobileInformation",
        _vm._b(
          {},
          "MobileInformation",
          { current_page: _vm.current_page },
          false
        )
      ),
      _c("div", { staticClass: "form-container" }, [
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n        Startup name\n        "),
            _vm.form_errors.startup_name
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.startup_name[0])),
                ])
              : _vm._e(),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.startup_name,
                expression: "form.startup_name",
              },
            ],
            staticClass: "form-control",
            attrs: {
              name: "startup_name",
              placeholder: "Startup name",
              id: "startup_name",
              type: "text",
            },
            domProps: { value: _vm.form.startup_name },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "startup_name", $event.target.value)
                },
                function ($event) {
                  _vm.syncProjectNameWithStartupName()
                  _vm.form_errors.startup_name = []
                },
              ],
            },
          }),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("\n        Product name\n        "),
            _vm.form_errors.project_name
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.project_name[0])),
                ])
              : _vm._e(),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.project_name,
                expression: "form.project_name",
              },
            ],
            staticClass: "form-control",
            attrs: {
              name: "project_name",
              placeholder: "Product name",
              id: "project_name",
              type: "text",
              disabled: _vm.sameAsStartupName,
            },
            domProps: { value: _vm.form.project_name },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "project_name", $event.target.value)
                },
                function ($event) {
                  _vm.form_errors.project_name = []
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "check-product-name",
              on: {
                click: function ($event) {
                  return _vm.handleSameAsStartupName()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "/img/onboarding/client/" +
                    (_vm.sameAsStartupName ? "checked" : "unchecked") +
                    ".svg",
                  alt: "",
                },
              }),
              _vm._v("\n        Same as startup name\n      "),
            ]
          ),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v("Description\n        "),
            _vm.form_errors.overview
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.overview[0])),
                ])
              : _vm._e(),
          ]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.overview,
                expression: "form.overview",
              },
            ],
            staticClass: "form-control",
            staticStyle: { height: "unset" },
            attrs: {
              name: "startup_bio",
              maxlength: "160",
              rows: "4",
              id: "textarea",
              placeholder: "Description of product, e.g Taxi's on Demand",
            },
            domProps: { value: _vm.form.overview },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "overview", $event.target.value)
                },
                function ($event) {
                  _vm.form_errors.overview = []
                },
              ],
              blur: _vm.handleValidateText,
            },
          }),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [
            _vm._v(" Industry\n        "),
            _vm.form_errors.industry
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.industry[0])),
                ])
              : _vm._e(),
          ]),
          _vm.isLoadingIndustry == true
            ? _c("div", { staticClass: "list-wrapper" }, [
                _c("img", {
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ])
            : _vm._e(),
          _vm.isLoadingIndustry == false
            ? _c(
                "div",
                { staticClass: "list-wrapper" },
                _vm._l(_vm.projectIndustriesData, function (item, index) {
                  return _c("ChipButton", {
                    key: index,
                    attrs: {
                      text: item.name,
                      dashedBorder: true,
                      buttonIcon: "img/interests/" + item.img,
                      selected: _vm.checkedSelectedItem(item.tag),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSelectIndustry(item.tag)
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("div", { staticClass: "div-label" }, [_vm._v("Investor backing")]),
          _c("div", { staticClass: "light-label" }, [
            _vm._v(
              "\n        Are you currently funded by any investor?\n\n        "
            ),
            _vm.form_errors.selectedBackedBy
              ? _c("span", { staticClass: "form_errors" }, [
                  _vm._v(_vm._s(_vm.form_errors.selectedBackedBy[0])),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "chip-btn-container" },
            [
              _c("ChipButton", {
                attrs: {
                  text: "Yes",
                  buttonIcon: "/img/onboarding/yes_answer.svg",
                  dashedBorder: true,
                  selected: _vm.form.selectedBackedBy == 1 ? true : false,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleBackedBy(1)
                  },
                },
              }),
              _c("ChipButton", {
                attrs: {
                  text: "Not yet",
                  buttonIcon: "/img/onboarding/not_yet_answer.svg",
                  dashedBorder: true,
                  selected: _vm.form.selectedBackedBy == 0 ? true : false,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleBackedBy(0)
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "button-container" }, [
            _vm.form.selectedBackedBy == 0
              ? _c("div", { staticClass: "form-grouped" }, [
                  _c(
                    "button",
                    {
                      staticClass: "grupa-blue-btn",
                      on: { click: _vm.handleSubmitFormTwo },
                    },
                    [
                      _vm._v("\n            Proceed to waitlist\n            "),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.spinner,
                            expression: "spinner",
                          },
                        ],
                        staticClass: "button-spinner",
                        staticStyle: { "margin-left": "20px" },
                        attrs: { src: "/img/lightbox/preloader.gif" },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm.form.selectedBackedBy == 1
          ? _c("div", [
              _c("div", { staticClass: "form-grouped" }, [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("\n          Investor name(s)\n          "),
                  _vm.form_errors.backed_by
                    ? _c("span", { staticClass: "form_errors" }, [
                        _vm._v(_vm._s(_vm.form_errors.backed_by[0])),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "grouped-form medium-combo-box" },
                  [
                    _c("v-combobox", {
                      staticClass: "overright-input",
                      attrs: {
                        "append-icon": "none",
                        "persistent-hint": "",
                        id: "backedBy",
                        "small-chips": "",
                        solo: "",
                        flat: "",
                        placeholder:
                          "Investor names, e.g. Founders Fund, Khosla Venuters, etc..",
                        color: "#979DA5",
                        multiple: "",
                      },
                      on: {
                        keypress: function ($event) {
                          return _vm.handleInvestorBoxKeyPresses($event)
                        },
                        blur: function ($event) {
                          return _vm.handleInvestorBoxLostFocus()
                        },
                      },
                      model: {
                        value: _vm.form.backed_by,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "backed_by", $$v)
                        },
                        expression: "form.backed_by",
                      },
                    }),
                    _c("div", { staticClass: "text-box-hint" }, [
                      _vm._v(
                        "\n            Separate VC names with a comma or tab.\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-grouped" }, [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("\n          Funding amount\n          "),
                  _vm.form_errors.funding_amount
                    ? _c("span", { staticClass: "form_errors" }, [
                        _vm._v(_vm._s(_vm.form_errors.funding_amount[0])),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "textbox-wrapper" }, [
                  _c("div", [_vm._v("USD")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.funding_amount,
                        expression: "form.funding_amount",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "funding_amount",
                      autocomplete: "off",
                      placeholder:
                        "What was your latest financing round? e.g 1,000,000",
                      id: "funding_amount",
                      maxlength: "11",
                      type: "text",
                    },
                    domProps: { value: _vm.form.funding_amount },
                    on: {
                      keypress: function ($event) {
                        return _vm.allowOnlyDigits()
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "funding_amount",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.handleFormatDigit("funding_amount")
                        },
                      ],
                      keyup: function ($event) {
                        _vm.form_errors.funding_amount = []
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-grouped" }, [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("Startup stage "),
                ]),
                _c("div", { staticClass: "light-label" }, [
                  _vm._v(
                    "\n          What stage is your startup currently?\n          "
                  ),
                  _vm.form_errors.stage_category_id
                    ? _c("span", { staticClass: "form_errors" }, [
                        _vm._v(_vm._s(_vm.form_errors.stage_category_id[0])),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "chip-btn-container" },
                  _vm._l(_vm.startupStages, function (item, index) {
                    return _c("ChipButton", {
                      key: index,
                      attrs: {
                        text: item.name,
                        dashedBorder: true,
                        buttonIcon: "img/product_stages/" + item.img,
                        selected:
                          item.value == _vm.form.stage_category_id
                            ? true
                            : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectStage(item.value)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c("div", { staticClass: "form-grouped" }, [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("Preferred compensation method"),
                ]),
                _c("div", { staticClass: "light-label" }, [
                  _vm._v(
                    "\n          How would you prefer to pay your experts?\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "chip-btn-container" },
                  _vm._l(_vm.compensationMethods, function (item, index) {
                    return _c("ChipButton", {
                      key: index,
                      attrs: {
                        text: item.name,
                        dashedBorder: true,
                        buttonIcon: "img/cash_bag.svg",
                        selected:
                          item.value == _vm.form.compensation_method
                            ? true
                            : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectCompensationType(item.value)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c("div", { staticClass: "form-grouped" }, [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("Desired weekly commitment"),
                ]),
                _c("div", { staticClass: "light-label" }, [
                  _vm._v(
                    "\n          How many hours of commitment per week do you want from your experts?\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "chip-btn-container" },
                  _vm._l(_vm.desiredHours, function (item, index) {
                    return _c("ChipButton", {
                      key: index,
                      attrs: {
                        text: item.name,
                        dashedBorder: true,
                        buttonIcon:
                          "img/onboarding/specify_preferences/" + item.img,
                        selected:
                          item.value == _vm.form.desired_hours ? true : false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectDesiredHours(item.value)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c("div", { staticClass: "form-grouped" }, [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("Number of experts"),
                ]),
                _c("div", { staticClass: "light-label" }, [
                  _vm._v(
                    "\n          How many experts do you want to hire?\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "chip-btn-container" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                        outlined: "",
                      },
                      model: {
                        value: _vm.form.no_of_engineers,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "no_of_engineers", $$v)
                        },
                        expression: "form.no_of_engineers",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "card-info-container" }, [
                _vm._m(1),
                _c("div", { staticClass: "information-text" }, [
                  _vm._v(
                    "\n          This is an estimate of how much it will cost to hire the desired number of experts with this compensation\n          method.\n        "
                  ),
                ]),
                _c("div", { staticClass: "information-text" }, [
                  _vm._v("\n          Cash component: "),
                  _c("span", { staticClass: "information-text-bold" }, [
                    _vm._v("$" + _vm._s(_vm.estimatedCost.cash) + " per month"),
                  ]),
                ]),
                _c("div", { staticClass: "information-text" }, [
                  _vm._v("\n          Equity component: "),
                  _c("span", { staticClass: "information-text-bold" }, [
                    _vm._v(
                      _vm._s(_vm.estimatedCost.equity) +
                        "% equity (3 month\n            cliff period)"
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.handleSubmitFormTwo },
                  },
                  [
                    _vm._v(
                      "\n          Proceed to Specify your Preferences\n          "
                    ),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("Tell us about your startup")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("img", {
        staticStyle: { "margin-left": "1px" },
        attrs: { src: "/img/BrownTag.svg" },
      }),
      _c("div", { staticClass: "info-header" }, [_vm._v("Estimated Costs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }