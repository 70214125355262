var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "MobileInformation",
        _vm._b(
          {},
          "MobileInformation",
          { current_page: _vm.current_page },
          false
        )
      ),
      _c(
        "div",
        { staticClass: "form-container" },
        [
          _c("div", { staticClass: "form-grouped" }, [
            _c("div", { staticClass: "div-label" }, [
              _vm._v("\n        Product Platform\n        "),
              _vm.form_errors.project_type
                ? _c("span", { staticClass: "form_errors" }, [
                    _vm._v(_vm._s(_vm.form_errors.project_type[0])),
                  ])
                : _vm._e(),
            ]),
            _vm.isLoadingPlatforms == true
              ? _c("div", { staticClass: "list-wrapper" }, [
                  _c("img", {
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ])
              : _vm._e(),
            _vm.isLoadingPlatforms == false
              ? _c(
                  "div",
                  { staticClass: "list-wrapper" },
                  _vm._l(_vm.platformsData, function (item, index) {
                    return _c("ChipButton", {
                      key: index,
                      attrs: {
                        text: item.name,
                        buttonIcon: item.img,
                        dashedBorder: true,
                        selected: _vm.checkedSelectedItem(
                          item.tag,
                          "platforms"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectPlatform(item.tag)
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "form-grouped" }, [
            _c("div", { staticClass: "div-label" }, [
              _vm._v("\n        Team Roles\n        "),
              _vm.form_errors.looking_for
                ? _c("span", { staticClass: "form_errors" }, [
                    _vm._v(_vm._s(_vm.form_errors.looking_for[0])),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "light-label" }, [
              _vm._v(
                "\n        What team roles do you need? You can always expand these later.\n      "
              ),
            ]),
            _vm.isLoadingRoles == true
              ? _c("div", { staticClass: "chip-btn-container" }, [
                  _c("img", {
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ])
              : _vm._e(),
            _vm.isLoadingRoles == false
              ? _c(
                  "div",
                  { staticClass: "chip-btn-container" },
                  _vm._l(_vm.teamRolesArrData, function (item, index) {
                    return _c("ChipButton", {
                      key: index,
                      attrs: {
                        text: item.name,
                        dashedBorder: true,
                        buttonIcon:
                          "img/onboarding/specify_preferences/" + item.img,
                        selected: _vm.checkedSelectedTeamRole(item.id),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectTeamRole(item.id)
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "form-grouped" }, [
            _c("div", { staticClass: "div-label" }, [
              _vm._v("\n        Tech Stacks\n        "),
              _vm.form_errors.selectedStacks
                ? _c("span", { staticClass: "form_errors" }, [
                    _vm._v(_vm._s(_vm.form_errors.selectedStacks[0])),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "light-label" }, [
              _vm._v(
                "\n        Is there a technology you would prefer to have your product built in?\n      "
              ),
            ]),
            _c(
              "div",
              { staticClass: "chip-btn-container" },
              [
                _c("ChipButton", {
                  attrs: {
                    text: "Yes",
                    buttonIcon: "/img/onboarding/specify_preferences/yes.png",
                    dashedBorder: true,
                    selected: _vm.form.selectedStacks == 1 ? true : false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleTechStackPick(1)
                    },
                  },
                }),
                _c("ChipButton", {
                  attrs: {
                    text: "No Preference",
                    buttonIcon: "/img/onboarding/specify_preferences/no.png",
                    dashedBorder: true,
                    selected: _vm.form.selectedStacks == 0 ? true : false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleTechStackPick(0)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.form.selectedStacks == 1
            ? _c("div", { staticClass: "form-grouped" }, [
                _c(
                  "div",
                  { staticClass: "grouped-form" },
                  [
                    _c("div", { staticClass: "text-box-hint" }, [
                      _vm._v(
                        "\n          Separate Tech stacks with a comma or tab\n        "
                      ),
                    ]),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-combobox", {
                          staticClass: "vue_select",
                          attrs: {
                            items: _vm.all_tools,
                            "item-value": "name",
                            "item-text": "name",
                            id: "backedBy",
                            "small-chips": "",
                            solo: "",
                            flat: "",
                            label: "Tech Stacks",
                            color: "#979DA5",
                            multiple: "",
                            "search-input": _vm.toolsSearchInput,
                          },
                          on: {
                            "update:searchInput": function ($event) {
                              _vm.toolsSearchInput = $event
                            },
                            "update:search-input": function ($event) {
                              _vm.toolsSearchInput = $event
                            },
                            keypress: function ($event) {
                              return _vm.handleCommaTwo($event)
                            },
                            change: _vm.updateStack,
                          },
                          model: {
                            value: _vm.toolLanguages,
                            callback: function ($$v) {
                              _vm.toolLanguages = $$v
                            },
                            expression: "toolLanguages",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "form-grouped" }, [
            _c("div", { staticClass: "div-label" }, [
              _vm._v("\n        Start Date\n        "),
              _vm.form_errors.kick_off
                ? _c("span", { staticClass: "form_errors" }, [
                    _vm._v(_vm._s(_vm.form_errors.kick_off[0])),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "light-label" }, [
              _vm._v("How soon would you commit to starting?"),
            ]),
            _c(
              "div",
              { staticClass: "chip-btn-container" },
              _vm._l(_vm.kickOffPlans, function (item, index) {
                return _c("ChipButton", {
                  key: index,
                  attrs: {
                    text: item.name,
                    dashedBorder: true,
                    buttonIcon:
                      "img/onboarding/specify_preferences/" + item.img,
                    selected: item.value == _vm.form.kick_off ? true : false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleKickoffSelectStage(item.value)
                    },
                  },
                })
              }),
              1
            ),
          ]),
          _c("div", { staticClass: "form-grouped" }, [
            _c("div", { staticClass: "div-label" }, [
              _vm._v("\n        How did you hear about us?\n        "),
              _vm.form_errors.hear_about_us
                ? _c("span", { staticClass: "form_errors" }, [
                    _vm._v(_vm._s(_vm.form_errors.hear_about_us[0])),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "light-label" }, [
              _vm._v(
                "\n        We would like to know how you heard about Grupa.\n      "
              ),
            ]),
            _vm.isLoadingHearAboutUs == true
              ? _c("div", { staticClass: "chip-btn-container" }, [
                  _c("img", {
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ])
              : _vm._e(),
            _vm.isLoadingHearAboutUs == false
              ? _c(
                  "div",
                  { staticClass: "chip-btn-container" },
                  _vm._l(_vm.getHearAboutUsArray, function (item, index) {
                    return _c("ChipButton", {
                      key: index,
                      attrs: {
                        text: item.name,
                        dashedBorder: true,
                        selected: _vm.checkedSelectedHearAboutUs(item.id),
                        buttonIcon:
                          "img/onboarding/specify_preferences/" + item.img,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSelectHearAboutUs(item.id)
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ]),
          _vm.form.kick_off > 2
            ? _c("div", { staticClass: "card-info-container" }, [
                _vm._m(1),
                _c("div", { staticClass: "information-text" }, [
                  _vm._v(
                    "\n        Join our waiting list and we’ll match you with a team in due time.\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "button-container" }, [
            _c(
              "button",
              {
                staticClass: "grupa-blue-btn",
                on: { click: _vm.handleTeamPreference },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.form.kick_off < 3
                        ? "Proceed to Build your Team"
                        : "Join Waitlist"
                    ) +
                    "\n        "
                ),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spinner,
                      expression: "spinner",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ]
            ),
          ]),
          _c(
            "NewHearAboutUsVue",
            _vm._b(
              {},
              "NewHearAboutUsVue",
              {
                getHearAboutUsArray: _vm.getHearAboutUsArray,
                toggleModal: _vm.toggleModal,
                toggleFun: _vm.toggleFun,
                finishSaveToList: _vm.finishSaveToList,
              },
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("Specify Your Preferences")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("div", { staticClass: "info-header" }, [_vm._v("We understand!")]),
      _c("img", {
        staticStyle: { "margin-left": "1px" },
        attrs: { src: "/img/thumbs-up.svg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }