var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "navbar-container" }, [_c("Navbar")], 1),
    _c("section", { staticClass: "section-wrapper" }, [
      _c(
        "div",
        { staticClass: "page-wrapper" },
        [
          _c(
            "SideNavigation",
            _vm._b(
              {},
              "SideNavigation",
              { current_page: _vm.current_page },
              false
            )
          ),
          _c("div", { staticClass: "right-side" }, [
            _c(
              "div",
              { staticClass: "new-product-wrapper" },
              [
                _c(
                  "div",
                  { staticClass: "tabs-container" },
                  _vm._l(_vm.regStages, function (step) {
                    return _c(
                      "div",
                      { key: step.stage, staticClass: "tab-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-button",
                            class: {
                              "active-tab": _vm.current_page == step.stage,
                              "completed-tab": _vm.current_page > step.stage,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setPage(step.stage)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(step.name) +
                                "\n              "
                            ),
                          ]
                        ),
                        step.stage < _vm.regStages.length
                          ? _c("div", { staticClass: "tab-line" })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _vm.current_page == 1
                  ? _c(
                      "AboutStartup",
                      _vm._b(
                        {},
                        "AboutStartup",
                        {
                          form_errors: _vm.form_errors,
                          form: _vm.form,
                          spinner: _vm.spinner,
                          handleBackedBy: _vm.handleBackedBy,
                          handleSubmitFormTwo: _vm.handleSubmitFormTwo,
                          handleCreateProject: _vm.handleCreateProject,
                          current_page: _vm.current_page,
                          handleValidateText: _vm.handleValidateText,
                        },
                        false
                      )
                    )
                  : _vm._e(),
                _vm.current_page == 2
                  ? _c(
                      "SpecifyPreferences",
                      _vm._b(
                        {},
                        "SpecifyPreferences",
                        {
                          form_errors: _vm.form_errors,
                          form: _vm.form,
                          handleTeamPreference: _vm.handleTeamPreference,
                          spinner: _vm.spinner,
                          handleTechStackPick: _vm.handleTechStackPick,
                          setPage: _vm.setPage,
                          all_tools: _vm.all_tools,
                          current_page: _vm.current_page,
                        },
                        false
                      )
                    )
                  : _vm._e(),
                _vm.successMsg
                  ? _c("AlertSuccess", { attrs: { time: "7" } })
                  : _vm._e(),
                _vm.errorMsg
                  ? _c("AlertError", { attrs: { time: "7" } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }