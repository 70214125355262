<template>
  <div>
    <div class="title">
      <h3>Tell us about your startup</h3>
    </div>
    <MobileInformation v-bind="{ current_page }" />
    <div class="form-container">

      <div class="form-grouped">
        <div class="div-label">
          Startup name
          <span class="form_errors" v-if="form_errors.startup_name">{{
            form_errors.startup_name[0]
          }}</span>
        </div>
        <input class="form-control" name="startup_name" v-model="form.startup_name" placeholder="Startup name"
          id="startup_name" type="text" @input="syncProjectNameWithStartupName(); form_errors.startup_name = []" />
      </div>

      <div class="form-grouped">
        <div class="div-label">
          Product name
          <span class="form_errors" v-if="form_errors.project_name">{{
            form_errors.project_name[0]
          }}</span>
        </div>
        <input class="form-control" name="project_name" v-model="form.project_name" placeholder="Product name"
          id="project_name" type="text" :disabled=sameAsStartupName @input="form_errors.project_name = []" />
        <div @click="handleSameAsStartupName()" class="check-product-name">
          <img :src="`/img/onboarding/client/${sameAsStartupName ? 'checked' : 'unchecked'
          }.svg`" alt="" />
          Same as startup name
        </div>
      </div>


      <div class="form-grouped">
        <div class="div-label">Description
          <span class="form_errors" v-if="form_errors.overview">{{
            form_errors.overview[0]
          }}</span>
        </div>
        <textarea class="form-control" v-model="form.overview" @input="form_errors.overview = []" name="startup_bio"
          maxlength="160" rows="4" id="textarea" style="height: unset" @blur="handleValidateText"
          placeholder="Description of product, e.g Taxi's on Demand"></textarea>
        <!-- <div
          class="brief_counter"
          style="text-align: left, font-size:10px; height:13px;"
        >
          <div v-show="showCount" style="text-align: left">
            {{ count }} | {{ max_length }}
            <span v-bind:class="{ brief_limit: isLimit }">
              Remaining: {{ remainder }}</span
            >
          </div>
        </div> -->
      </div>
      <div class="form-grouped">
        <div class="div-label"> Industry
          <span class="form_errors" v-if="form_errors.industry">{{
            form_errors.industry[0]
          }}</span>
        </div>
        <!-- <label class="label-description"
          >What industries does your product fit in?
        </label> -->
        <div class="list-wrapper" v-if="isLoadingIndustry == true">
          <img src="/img/lightbox/preloader.gif" style="margin-left: 10px" class="button-spinner" />
        </div>
        <div class="list-wrapper" v-if="isLoadingIndustry == false">
          <ChipButton v-for="(item, index) in projectIndustriesData" :key="index" :text="item.name" :dashedBorder="true"
            :buttonIcon="'img/interests/' + item.img" :selected="checkedSelectedItem(item.tag)"
            v-on:click="handleSelectIndustry(item.tag)" />
        </div>
      </div>
      <!-- <div class="form-grouped medium-combo-box">
        <div class="div-label">
          Founders’ email address(es) <span class="light-text-label">
            (optional)
          </span>
          <span class="form_errors" v-if="form_errors.collaborators">{{
            form_errors.collaborators[0]
          }}</span>
        </div>
        <div class="light-label">
          This founder(s) will be invited to collaborate on your project.
        </div>
        <div class="grouped-form">
          <v-combobox
            v-model="form.collaborators"
            append-icon="none"
            persistent-hint
            id="emails"
            small-chips
            solo
            flat
            placeholder="example@yourcompany.com"
            color="#979DA5"
            class="overright-input"
            multiple
            @keypress="handleComma($event)"
          ></v-combobox>
          <div class="text-box-hint">
            Separate multiple email addresses with a comma or tab.
          </div>
        </div>
      </div> -->
      <div class="form-grouped">
        <div class="div-label">Investor backing</div>
        <div class="light-label">
          Are you currently funded by any investor?

          <span class="form_errors" v-if="form_errors.selectedBackedBy">{{
            form_errors.selectedBackedBy[0]
          }}</span>
        </div>
        <div class="chip-btn-container">
          <ChipButton text="Yes" buttonIcon="/img/onboarding/yes_answer.svg" :dashedBorder="true"
            v-on:click="handleBackedBy(1)" :selected="form.selectedBackedBy == 1 ? true : false" />
          <ChipButton text="Not yet" buttonIcon="/img/onboarding/not_yet_answer.svg" :dashedBorder="true"
            v-on:click="handleBackedBy(0)" :selected="form.selectedBackedBy == 0 ? true : false" />
        </div>
        <div class="button-container">
          <div v-if="form.selectedBackedBy == 0" class="form-grouped">
            <button class="grupa-blue-btn" @click="handleSubmitFormTwo">
              Proceed to waitlist
              <img src="/img/lightbox/preloader.gif" style="margin-left: 20px" class="button-spinner"
                v-show="spinner" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="form.selectedBackedBy == 1">
        <div class="form-grouped">
          <div class="div-label">
            Investor name(s)
            <span class="form_errors" v-if="form_errors.backed_by">{{
              form_errors.backed_by[0]
            }}</span>
          </div>

          <div class="grouped-form medium-combo-box">
            <v-combobox v-model="form.backed_by" append-icon="none" persistent-hint id="backedBy" small-chips solo flat
              placeholder="Investor names, e.g. Founders Fund, Khosla Venuters, etc.." color="#979DA5"
              class="overright-input" multiple @keypress="handleInvestorBoxKeyPresses($event)"
              @blur="handleInvestorBoxLostFocus()"></v-combobox>
            <!-- :items="investorList"
              item-value="name"
              item-text="name" -->
            <div class="text-box-hint">
              Separate VC names with a comma or tab.
            </div>
          </div>
        </div>
        <div class="form-grouped">
          <div class="div-label">
            Funding amount
            <span class="form_errors" v-if="form_errors.funding_amount">{{
              form_errors.funding_amount[0]
            }}</span>
          </div>

          <div class="textbox-wrapper">
            <div>USD</div>
            <input class="form-control" name="funding_amount" v-model="form.funding_amount" autocomplete=off
              placeholder="What was your latest financing round? e.g 1,000,000" id="funding_amount"
              @keypress="allowOnlyDigits()" maxlength="11" type="text" @input="handleFormatDigit('funding_amount')"
              @keyup="form_errors.funding_amount = []" />
            <!-- @input="" -->
          </div>
        </div>
        <div class="form-grouped">
          <div class="div-label">Startup stage </div>
          <div class="light-label">
            What stage is your startup currently?
            <span class="form_errors" v-if="form_errors.stage_category_id">{{
              form_errors.stage_category_id[0]
            }}</span>
          </div>
          <div class="chip-btn-container">
            <ChipButton v-for="(item, index) in startupStages" :key="index" :text="item.name" :dashedBorder="true"
              :buttonIcon="'img/product_stages/' + item.img"
              :selected="item.value == form.stage_category_id ? true : false"
              v-on:click="handleSelectStage(item.value)" />
          </div>
        </div>

        <div class="form-grouped">
          <div class="div-label">Preferred compensation method</div>
          <div class="light-label">
            How would you prefer to pay your experts?
          </div>
          <div class="chip-btn-container">
            <ChipButton v-for="(item, index) in compensationMethods" :key="index" :text="item.name" :dashedBorder="true"
              buttonIcon="img/cash_bag.svg" :selected="item.value == form.compensation_method ? true : false"
              v-on:click="handleSelectCompensationType(item.value)" />
          </div>
        </div>

        <div class="form-grouped">
          <div class="div-label">Desired weekly commitment</div>
          <div class="light-label">
            How many hours of commitment per week do you want from your experts?
          </div>
          <div class="chip-btn-container">
            <ChipButton v-for="(item, index) in desiredHours" :key="index" :text="item.name" :dashedBorder="true"
              :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
              :selected="item.value == form.desired_hours ? true : false"
              v-on:click="handleSelectDesiredHours(item.value)" />
          </div>
        </div>

        <div class="form-grouped">
          <div class="div-label">Number of experts</div>
          <div class="light-label">
            How many experts do you want to hire?
          </div>
          <div class="chip-btn-container">
            <v-select :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" outlined v-model="form.no_of_engineers"></v-select>
          </div>
        </div>

        <div class="card-info-container">
          <div class="title-wrapper">
            <img src="/img/BrownTag.svg" style="margin-left: 1px" />
            <div class="info-header">Estimated Costs</div>
          </div>
          <div class="information-text">
            This is an estimate of how much it will cost to hire the desired number of experts with this compensation
            method.
          </div>

          <div class="information-text">
            Cash component: <span class="information-text-bold">${{ estimatedCost.cash }} per month</span>
          </div>

          <div class="information-text">
            Equity component: <span class="information-text-bold">{{ estimatedCost.equity }}% equity (3 month
              cliff period)</span>
          </div>
        </div>
        <div class="button-container">
          <button class="grupa-blue-btn" @click="handleSubmitFormTwo">
            Proceed to Specify your Preferences
            <img src="/img/lightbox/preloader.gif" style="margin-left: 20px" class="button-spinner" v-show="spinner" />
          </button>
        </div>


      </div>
    </div>

    <!-- </form> -->
  </div>
</template>

<script>
import ChipButton from "@/components/chipButton/chipButton";
import MobileInformation from "./MobileInformation.vue";
import { getIndustry } from "@/api/client";


export default {
  props: {
    current_page: {
      required: true,
      type: Number,
    },
    form: {
      required: true,
      type: Object,
    },
    form_errors: {
      required: true,
      type: Object,
    },
    handleSubmitFormTwo: {
      required: true,
      type: Function,
    },
    spinner: { required: true },
    handleBackedBy: {
      required: true,
      type: Function,
    },
    handleCreateProject: {
      required: true,
      type: Function,
    },
    handleValidateText: {
      required: true,
      type: Function,
    },
  },
  components: {
    ChipButton,
    MobileInformation,
  },

  data() {
    return {
      sameAsStartupName: true,
      isLoadingIndustry: true,
      projectIndustriesData: [],
      /*  projectIndustries: [
         { name: "Finance", tag: "finance", img: "finance.svg" },
         { name: "Travel", tag: "travel", img: "travel.svg" },
         { name: "Security", tag: "security", img: "security.svg" },
         { name: "Education", tag: "education", img: "education.svg" },
         { name: "eCommerce", tag: "eCommerce", img: "eCommerce.svg" },
         { name: "Marketing", tag: "marketing", img: "marketing.svg" },
         { name: "Real Estate", tag: "real_estate", img: "real_estate.svg" },
         { name: "Blockchain", tag: "blockchain", img: "blockchain.svg" },
         { name: "Social Media", tag: "social", img: "social.svg" },
         { name: "Energy", tag: "energy", img: "energy.svg" },
         { name: "HR", tag: "hr", img: "hr.svg" },
         { name: "Hospitality", tag: "hospitality", img: "hospitality.svg" },
         { name: "Gaming", tag: "gaming", img: "gaming.svg" },
         { name: "Legal", tag: "legal", img: "legal.svg" },
         { name: "Others", tag: "others", img: "others.svg" },
       ], */
      startupStages: [
        {
          name: "Angel",
          value: 1,
          img: "angel.svg",
        },
        {
          name: "Pre-Seed",
          value: 2,
          img: "pre_seed.svg",
        },
        {
          name: "Seed",
          value: 3,
          img: "seed.svg",
        },
        {
          name: "Series A",
          value: 4,
          img: "series_a.svg",
        },
      ],
      compensationMethods: [
        {
          name: "Cash only",
          value: "cashonly",
        },
        {
          name: "A mix of Cash & Equity",
          value: "cashequity",
        },
        {
          name: "Equity only",
          value: "equityonly",
        },
      ],
      desiredHours: [
        {
          name: "20 hours",
          value: 20,
          img: "1_week.svg",
        },
        {
          name: "30 hours",
          value: 30,
          img: "2_weeks.svg",
        },
        {
          name: "40 hours",
          value: 40,
          img: "3_weeks.svg",
        },
      ],
      investorList: [
        { name: "Y Combinator", value: "Y Combinator" },
        { name: "500 Startups", value: "500 Startups" },
        { name: "Pear VC", value: "Pear VC" },
      ],
      selectedBackedBy: false,
      count: 0,
      showCount: false,
      remainder: 30,
      isLimit: false,
      max_length: 30,
    };
  },
  created() {


    getIndustry().then((data) => {

      let response = data.data.data
      this.isLoadingIndustry = false

      for (var i = 0; i < response.length; i++) {
        this.projectIndustriesData.push(

          { name: response[i].industry_name, tag: response[i].industry_code, img: response[i].industry_svg },
        )

      }




    }).catch((error) => {

      this.isLoadingIndustry = false


    })
  },
  methods: {
    handleSelectStage(value) {
      this.form.stage_category_id = value;
      this.form.stage_id = value;
      this.form_errors.stage_category_id = [];
    },

    handleSelectCompensationType(value) {
      this.form.compensation_method = value;
    },

    handleSelectDesiredHours(value) {
      this.form.desired_hours = value;
    },

    syncProjectNameWithStartupName() {
      if (this.sameAsStartupName) {
        this.form.project_name = this.form.startup_name;
      } else {
        this.form.project_name = "";
      }
    },
    handleSameAsStartupName() {
      this.sameAsStartupName = !this.sameAsStartupName;
      this.syncProjectNameWithStartupName();
    },
    acceptCurrentEntryInInvestorBox() {
      let el = document.getElementById("backedBy");
      el.value = el.value.trim();
      if (el.value === '') {
        return;
      }
      var e = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        char: "Tab",
        key: "Tab",
        shiftKey: true,
        keyCode: 9,
      });
      el.dispatchEvent(e);
    },
    handleInvestorBoxKeyPresses(event) {
      if (event.key == ",") {
        event.preventDefault();
        this.acceptCurrentEntryInInvestorBox();
      } else {
        event;
      }
    },
    handleInvestorBoxLostFocus() {
      this.acceptCurrentEntryInInvestorBox();
    },
    allowOnlyDigits(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 47 && charCode < 58) {
        return true;
      } else {
        evt.preventDefault();
      }
    },

    handleFormatDigit(type) {
      if (window.event.inputType.startsWith("deleteContent") || window.event.inputType === 'insertText') {
        let el = document.getElementById('funding_amount');
        const pos = el.selectionStart;
        const initialNumCommmas = (el.value.match(/\,/g) || []).length;
        if (pos >= 0) {
          this.$nextTick(
            () => {
              const newNumCommmas = (el.value.match(/\,/g) || []).length;
              if (initialNumCommmas > newNumCommmas) {
                el.selectionStart = pos - 1;
                el.selectionEnd = pos - 1;
              } else if (initialNumCommmas < newNumCommmas) {
                el.selectionStart = pos + 1;
                el.selectionEnd = pos + 1;
              } else {
                el.selectionStart = pos;
                el.selectionEnd = pos;
              }
            }
          );
        }
      }

      let value = this.form[type];
      if (value) {
        this.form[type] = this.formatNumber(value);
      }
    },
    formatNumber(value) {
      let currency = value;
      if (currency.includes(",")) {
        currency = currency.replace(/,/g, "");
      }
      return Number(currency).toLocaleString();
    },
    preventAction() {
      return false;
    },
    handleSelectIndustry(value) {
      let selected = this.form.industry;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.form.industry.push(value);
      } else {
        this.form.industry = selected.filter((item) => item !== value);
      }
      this.form_errors.industry = [];
    },
    countCharacters(e) {
      this.count = e.target.value.length;
      if (this.count >= 1) {
        this.showCount = true;
      } else {
        this.showCount = false;
      }
      if (this.count > 110) {
        this.isLimit = true;
      } else {
        this.isLimit = false;
      }
      this.remainder = this.max_length - this.count;
      // this.remainder =
      //   this.remainder > 0 ? this.max_length - this.count : this.remainder;
    },
  },
  computed: {
    checkedSelectedItem() {
      return (value, type) => {
        let selected =
          type == "platforms" ? this.form.project_type : this.form.industry;
        const foundItem = selected.find((item) => item == value);
        return foundItem ? true : false;
      };
    },

    estimatedCost() {
      let expertcount = this.form.no_of_engineers;
      let cashcost = 0, equityMultiplier, equityBaseMultiplier, equityHoursMultiplier, cashdiscount, equityCost;

      switch (this.form.compensation_method) {
        case "cashonly":
          cashcost = 20000
          equityBaseMultiplier = 0
          break;
        case "cashequity":
          cashcost = 15000
          equityBaseMultiplier = 1
          break;
        case "equityonly":
          cashcost = 1000
          equityBaseMultiplier = 2.5
          break;
        default:
          equityBaseMultiplier = 1
      }

      switch (this.form.desired_hours) {
        case 20:
          cashdiscount = 7500;
          equityHoursMultiplier = 1;
          break;
        case 30:
          cashdiscount = 5000
          equityHoursMultiplier = 1.5;
          break;
        case 40:
          cashdiscount = 0
          equityHoursMultiplier = 2.0;
          break;
        default:
          cashdiscount = 0
          equityHoursMultiplier = 1;
      }


      switch (this.form.stage_id) {
        case 1: // angel
          equityCost = 1
          break;
        case 2: // pre seed
          equityCost = 0.5
          break;
        case 3: // seed
          equityCost = 0.25
          break;
        case 4: // series A
          equityCost = 0.125
          break;
        default:
          equityCost = 1
      }

      equityMultiplier = equityBaseMultiplier * equityHoursMultiplier;
      return {
        cash: (Number(expertcount) * Math.max(1000, cashcost - cashdiscount)).toLocaleString(),
        equity: (Math.round(1000 * Number(expertcount) * equityCost * equityMultiplier)) / 1000
      }
    }
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

div.title {
  text-align: left;
  padding-top: 56px;
  padding-left: 5px;
  font-size: 23px;
  font-weight: bold;
}

.form-container {
  width: 588px;
  padding-top: 20px;
}

.div-label {
  text-align: left;
  font-size: 14px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 145%;
}

.form-control {
  border: 1px solid #e4e5e7;
}

.form-control {
  margin-top: 4px;
  margin-bottom: 0px;
}

.form-grouped {
  margin-top: 48px;
}

.form_errors {
  color: red;
}

.check-product-name {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 8px;
}

.light-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
  text-align: left;
}

.chip-btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.button-container {
  /*display: flex;*/
  justify-content: flex-end;
  margin: 48px 0px;
  float: left;
}

.text-box-hint {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}

.medium-combo-box .v-text-field {
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 130%;
}

.textbox-wrapper {
  display: flex;
}

.textbox-wrapper div {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  /* background-color: #fff; */
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;
  width: 42px;
  background: black;
  color: white;
  height: 51px;
}

.light-text-label {
  color: #979da5;
}

.display-inline {
  display: inline-block;
  /* padding-top: 50px; */
}

.list-wrapper {
  display: flex;
  margin-top: 5px;
  gap: 12px;
  width: 588px;
  flex-wrap: wrap;
}

.label-description {
  color: #979da5;
}

.brief_counter {
  margin-bottom: 28px;
}

@media (max-width: 768px) {
  .form-container {
    width: 100%;
  }

  .button-container {
    float: none;
  }

  .grupa-blue-btn {
    width: 100%;
  }

  .list-wrapper {
    width: 100%;
  }

  div.title {
    padding-top: 32px;
  }

  .chip-btn-container {
    width: 100%;
  }

  .form-grouped {
    margin-top: 32px;
  }
}

.card-info-container {
  background: rgba(7, 129, 242, 0.05);
  border-radius: 4px;
  width: 427px;
  text-align: left;
  padding: 24px;
  margin-top: 48px;
}

.title-wrapper {
  display: flex;
  vertical-align: top;
  position: relative;
  margin-bottom: 18px;
}

.title-wrapper img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.info-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #1B1E22;
  margin-left: 32px;
}

.information-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #979DA5;
}

.information-text-bold {
  font-weight: 700;
  color: #1B1E22;
}

.information-text:not(:last-child) {
  margin-bottom: 16px;
}
</style>
