<template>
  <div class="onboarding-info">
    <div class="onboarding-img">
      <img src="/img/onboarding/get-started.svg" alt="" />
    </div>
    <div class="stage-text">
      {{
        current_page == 1
          ? aboutProject
          : current_page == 2
          ? aboutStartup
          : getStarted
      }}
    </div>
    <div class="stage-text">
      {{
        current_page == 2
          ? startupSubtext
          : current_page == 3
          ? getStartedText
          : ""
      }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current_page: {
      required: true,
      type: Number,
    },
  },
  components: {},

  data: () => ({
    aboutProject: `Give us some info about your product. This helps us find you the right
        team and customize an overall experience that suits you best.`,
    aboutStartup: `Great talents want to work with equally great people.`,
    getStarted: `What are you looking out for in the product team you wish to hire and how will you prefer to engage?`,
    startupSubtext: `Tell us about your startup and the amazing team (co-founders and VCs) backing this product idea.`,
    getStartedText: `This helps us narrow down the search and not show you teams that do not fit.`,
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.onboarding-info {
  margin-top: 12px;
  display: none;
  text-align: left;
}
.stage-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  text-align: left;
  margin-top: 12px;
}
.onboarding-img img {
  width: 128px;
  height: 128px;
}
@media (max-width: 768px) {
  .onboarding-info {
    display: block;
  }
}
</style>
