<template>
  <div>
    <div class="navbar-container">
      <Navbar />
    </div>
    <section class="section-wrapper">
      <div class="page-wrapper">
        <SideNavigation v-bind="{ current_page }" />
        <div class="right-side">
          <div class="new-product-wrapper">
            <div class="tabs-container">
              <div class="tab-box" v-for="step in regStages" :key="step.stage">
                <div
                  class="tab-button"
                  v-on:click="setPage(step.stage)"
                  :class="{
                    'active-tab': current_page == step.stage,
                    'completed-tab': current_page > step.stage,
                  }"
                >
                  {{ step.name }}
                </div>
                <div
                  class="tab-line"
                  v-if="step.stage < regStages.length"
                ></div>
              </div>
            </div>

            <AboutStartup
              v-if="current_page == 1"
              v-bind="{
                form_errors,
                form,
                spinner,
                handleBackedBy,
                handleSubmitFormTwo,
                handleCreateProject,
                current_page,
                handleValidateText,
              }"
            />
            <!-- <AboutProduct
              v-if="current_page == 2"
              v-bind="{ handleSubmitFormOne, form_errors, form, current_page }"
            /> -->

            <SpecifyPreferences
              v-if="current_page == 2"
              v-bind="{
                form_errors,
                form,
                handleTeamPreference,
                spinner,
                handleTechStackPick,
                setPage,
                all_tools,
                current_page,
              }"
            />
            <AlertSuccess v-if="successMsg" time="7" />
            <AlertError v-if="errorMsg" time="7" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validateEmail } from "@/utils/validationHelpers";
import mixPanel from "@/mixins/mixPanel";
import Navbar from "@/components/Navbar/onboarding_navbar";
import { fetchToolsAndFrameWorks } from "@/api";
import { createProject } from "@/api/client";
import SideNavigation from "./partials/SideNavigation.vue";
import AboutProduct from "./partials/AboutProduct.vue";
import AboutStartup from "./partials/AboutStartup.vue";
import SpecifyPreferences from "./partials/specify_preferences.vue";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";


export default {
  mixins: [mixPanel],
  components: {
    Navbar,
    AboutProduct,
    SideNavigation,
    AboutStartup,
    SpecifyPreferences,
    AlertSuccess,
    AlertError,
  },
  data() {
    return {
      regStages: [
        {
          stage: 1,
          name: "1. Startup Info",
        },
        {
          stage: 2,
          name: "2. Get Started",
        },
      ],
      project: {
        platform: "",
        is_new: "",
        industry: "",
      },

      form_errors: {
        project_name: [],
        overview: [],
        project_type: [],
        industry: [],
        startup_name: [],
        founder_name: [],
        startup_bio: [],
        stage_category_id: [],
        selectedBackedBy: [],
        funding_amount: [],
        backed_by: [],
        collaborators: [],
        most_recent_valuation: [],
        looking_for: [],
        selectedStacks: [],
        commitmentLevel: [],
        compensation_id: [],
        kick_off: [],
        hear_about_us: [],
      },
      form: {
        project_name: "",
        overview: "",
        startup_name: "",
        founder_name: "",
        project_type: [],
        /*  project_id: this.clientProject.hasOwnProperty("id")
          ? this.clientProject.id
          : null, */
        industry: [],
        stage_category_id: 1,
        stage_id: 1,
        backed_by: "",
        selectedBackedBy: "nil",
        collaborators: [],
        looking_for: [],
        stack: [],
        selectedStacks: "nil",
        commitmentLevel: 0,
        compensation_id: 0,
        compensation_method: "cashonly",
        desired_hours: 20,
        no_of_engineers: 1,
        kick_off: 0,
        bio: "",
        funding_amount: "",
        most_recent_valuation: "",
        startup_name: "",
        startup_bio: "",
        is_waiting: "",
        is_priority: "",
        is_startup_fit: 1,
        hear_about_us: "",
      },
      current_page: 1,
      spinner: false,
      project_id: null,
      loader: false,
      all_tools: [],
      errorMessage: `(Required)`,
      isFormValid: true,
    };
  },
  created() {
    this.getToolsAndFrameWorks();
    let projects = this.clientProject;
    console.log("projects", projects);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      vm.goBackProject(from);
    });
  },
  methods: {
    ...mapActions("userManagement", ["setAutheticatedUser"]),
    //  ...mapState("clientDashboard", ["clientProject", "allProjects"]),
    ...mapActions("clientDashboard", [
      "updateProject",
      //"clientProject",
      "allProjects",
      // "setAllProjects",
    ]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    handleCreateProject() {
      this.spinner = true;
      let { funding_amount: amountRaised } = this.form;
      if (amountRaised && amountRaised.toString().includes(",")) {
        const funding_amount = amountRaised.replace(/,/g, "");
        this.form.funding_amount = Number(funding_amount);
      }
      const method = this.form.project_id ? "put" : "";
      // console.log(this.form)
      //alert("method :: " + method);
      createProject(this.form, method)
        .then((response) => {
          const { is_priority, is_startup_fit } = this.form;
          if (response.data.status == 1) {
            this.updateProject({
              ...response.data.data,
              compensation_method: this.form.compensation_method,
              no_of_engineers: this.form.no_of_engineers,
              desired_hours: this.form.desired_hours,
            });
            const projectId = response.data.data.id;
            if (is_priority) {
              this.$router.push({
                name: "preview_potential_team",
                params: { id: projectId },
              });
              this.mixPanelTrack({
                event: "GoToPriorityList",
              });
            } else {
              this.$router.push({
                name: "waiting_list",
                params: { id: projectId },
              });
              this.mixPanelTrack({
                event: "StartupInfoWaitList",
              });
            }
          }
        })
        .catch((err) => {
          var errMsg = err.response.data.errors;
          var result = [];

          for (let [key, value] of Object.entries(errMsg)) {
            result.push(value[0]);
          }

          this.setErrorMsg(result);
          this.spinner = false;
          this.mixPanelTrack({
            event: "FailToSaveStartupInfo",
          });
        });
    },
    handleSubmitFormTwo() {
      this.form_errors = {
        startup_name: [],
        startup_bio: [],
        stage_category_id: [],
        selectedBackedBy: [],
        funding_amount: [],
        most_recent_valuation: [],
        // no_of_engineers:0,
        backed_by: [],
        overview: [],
        industry: [],
        hear_about_us: [],
      };
      const {
        startup_name,
        stage_category_id,
        selectedBackedBy,
        backed_by,
        overview,
        industry,
        hear_about_us,
        funding_amount,
      } = this.form;
      // console.log(this.form);
      this.form.project_name = startup_name;
      const errorMessage = this.errorMessage;
      if (!startup_name) {
        this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
        //this.setErrorMsg("Start up Name: The startup name field is required");

        this.form_errors.startup_name[0] = errorMessage;
      }
      if (!overview || overview.length < 30) {
        this.form_errors.overview[0] = "(Required: Min 30 Char)";
      }
      if (industry.length === 0) {
        //this.setErrorMsg("Industry: The industry field is required");
        this.form_errors.industry[0] = errorMessage;
      }
      if (
        startup_name &&
        selectedBackedBy === 0 &&
        overview.length > 29 &&
        industry.length > 0
      ) {
        this.handleCreateProject();
      }
      if (selectedBackedBy === "nil") {
        this.form_errors.selectedBackedBy[0] = errorMessage;
      }
      if (selectedBackedBy == 1 && !backed_by) {
        this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
        this.form_errors.backed_by[0] = errorMessage;
      }
      if (selectedBackedBy == 1 && !stage_category_id) {
        this.form_errors.stage_category_id[0] = errorMessage;
        this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
      }
      if (selectedBackedBy == 1 && !funding_amount) {
        this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
        this.form_errors.funding_amount[0] = errorMessage;
      }
      if (
        selectedBackedBy === 1 &&
        backed_by &&
        stage_category_id &&
        funding_amount &&
        startup_name &&
        industry.length > 0 &&
        overview.length > 29
      ) {
        this.current_page = 2;
        this.mixPanelTrack({
          event: "SaveStartupInfo",
          startupName: startup_name,
        });
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    },
    handleSubmitFormOne() {
      this.form_errors = {
        project_name: [],
        project_type: [],
        industry: [],
        overview: [],
      };
      const { project_name, overview, project_type, industry } = this.form;
      const errorMessage = this.errorMessage;
      if (!project_name) {
        this.form_errors.project_name[0] = errorMessage;
      }
      if (project_name && project_name.length < 4) {
        this.form_errors.project_name[0] = "(Required: Min 4 Char)";
      }
      if (!overview || overview.length < 30) {
        this.form_errors.overview[0] = "(Required: Min 30 Char)";
      }
      if (project_type.length === 0) {
        this.form_errors.project_type[0] = errorMessage;
      }
      if (industry.length === 0) {
        this.form_errors.industry[0] = errorMessage;
      }
      if (
        project_name.length > 3 &&
        overview.length > 29 &&
        project_type.length > 0 &&
        industry.length > 0
      ) {
        this.current_page = 2;
        this.mixPanelTrack({
          event: "SaveProductInfo",
          productName: project_name,
        });
      }
    },
    handleBackedBy(val) {
      if (val == 0) {
        this.form.backed_by = "";
        this.form.is_startup_fit = 0;
        this.form.is_waiting = 1;
      } else {
        this.form.is_startup_fit = 1;
        this.form.is_waiting = 0;
      }
      this.form.selectedBackedBy = val;
      this.form_errors.selectedBackedBy = [];
    },
    handleTechStackPick(val) {
      if (val == 0) {
        this.form.stack = [];
      }
      this.form.selectedStacks = val;
      this.form_errors.selectedStacks = [];
    },
    setPage(pageNo) {
      if (this.current_page >= pageNo) {
        this.current_page = pageNo;
      }
    },
    getToolsAndFrameWorks() {
      fetchToolsAndFrameWorks()
        .then((response) => {
          if (response.data.status == 1) {
            this.all_tools = response.data.data.sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            );
          }
        })
        .catch((err) => {});
    },
    handleTeamPreference() {
      this.form_errors = {
        looking_for: [],
        selectedStacks: [],
        kick_off: [],
        project_type: [],
        hear_about_us: [],
      };
      const {
        looking_for,
        selectedStacks,
        kick_off,
        project_type,
        hear_about_us,
      } = this.form;
      const errorMessage = this.errorMessage;
      if (project_type.length === 0) {
        this.form_errors.project_type[0] = errorMessage;
      }
      if (looking_for.length === 0) {
        this.form_errors.looking_for[0] = errorMessage;
      }
      if (selectedStacks === "nil") {
        this.form_errors.selectedStacks[0] = errorMessage;
      }
      if (!kick_off) {
        this.form_errors.kick_off[0] = errorMessage;
      }
      if (!hear_about_us) {
        this.form_errors.hear_about_us[0] = errorMessage;
      }

      

      if (
        project_type.length > 0 &&
        looking_for.length > 0 &&
        selectedStacks !== "nil" &&
        kick_off && hear_about_us
      ) {
       // console.log(this.form);
         this.handleCreateProject();
        this.mixPanelTrack({
          event: "SaveTeamPreferences",
        });
      }
      else {
         this.setErrorMsg(
          "One or more required field was not filled out properly"
        );
      }
    },
    handleValidateEmail() {
      let { collaborators } = this.form;
      if (collaborators) {
        this.form_errors.collaborators = [];
        let invalid = [];
        collaborators.map((email) => {
          let result = validateEmail(email);
          if (result) {
            invalid.push(email);
          }
        });
        if (invalid.length > 0) {
          if (invalid.length == 1) {
            this.form_errors.collaborators[0] = `${invalid[0]} is not a valid email`;
          } else {
            this.form_errors.collaborators[0] = `The following emails are not valid ${invalid.map(
              (email) => `${email} `
            )}`;
          }
          this.isFormValid = false;
          return false;
        }
        this.isFormValid = true;
        return true;
      }
      this.isFormValid = true;
      return true;
    },
    goBackProject: function (Route) {
      if (
        Route.name === "reserve_team" ||
        Route.name === "project_workspace" ||
        Route.name === "preview_potential_team"
      ) {
        let {
          id: project_id,
          startup,
          project_name,
          project_type,
          overview,
          funding_amount,
          looking_for,
          kick_off,
          stack,
          is_startup_fit,
          compensation_method,
          desired_hours,
          no_of_engineers,
          hear_about_us,
        } = this.projectOverview;
        let {
          name: startup_name,
          industry,
          backed_by,
          stage_category_id,
          is_priority,
          is_waiting,
        } = startup;
        let form = this.form;
        funding_amount;
        Number(funding_amount).toLocaleString();
        const productInterest = JSON.parse(industry);
        const productType = JSON.parse(project_type);
        const investor = JSON.parse(backed_by);
        const teamExpert = JSON.parse(looking_for);
        const selectedBackedBy = investor ? 1 : 0;
        const selectedStacks = stack ? 1 : 0;
        const techStack = stack ? JSON.parse(stack) : [];

        this.form = {
          ...form,
          project_id,
          startup_name,
          overview,
          project_name,
          industry: productInterest,
          project_type: productType,
          backed_by: investor,
          selectedBackedBy,
          funding_amount,
          stage_category_id,
          looking_for: teamExpert,
          kick_off,
          stack: techStack,
          selectedStacks,
          is_priority,
          is_waiting,
          is_startup_fit,
          compensation_method,
          desired_hours,
          no_of_engineers,
        };
        if (selectedBackedBy) {
          this.current_page = 2;
        }
      }
    },
    handleValidateText() {
      const { overview } = this.form;
      this.form_errors.overview = [];
      if (!overview || overview.length < 30) {
        this.form_errors.overview[0] = "(Required: Min 30 Char)";
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("clientDashboard", ["clientProject"]),
    projectOverview() {
      return this.clientProject;
    },
  },
};
</script>

<style scoped>
.navbar-container {
  position: fixed;
  /* width: 100%; */
  width: 100vw;
  z-index: 100;
}

.section-wrapper {
  margin-top: 80px;
}

.page-wrapper {
  display: flex;
}

.new-product-wrapper {
  margin: 5% 5%;
}

.tabs-container {
  display: flex;
  align-items: center;
}

.tab-box {
  display: flex;
  align-items: center;
}

.tab-button {
  padding: 8px 12px;
  color: #979da5;
  border: 1px solid #979da5;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  cursor: pointer;
}

.tab-line {
  border-top: 1px solid #e4e5e7;
  width: 80px;
  height: 1px;
}

.active-tab {
  border: 1px solid #1fa564;
  color: #1fa564;
}

.completed-tab {
  background: #53585f;
  color: #ffffff;
}

.right-side {
  width: calc(100% - 382px);
  margin-left: 382px;
}

@media (max-width: 1280px) {
  .right-side {
    width: 75%;
    margin-left: 25%;
  }
}

@media (max-width: 768px) {
  .right-side {
    width: 100%;
    margin-left: 0%;
  }
}
</style>
