<template>
  <div>
    <div class="title">
      <h3>Specify Your Preferences</h3>
    </div>
    <MobileInformation v-bind="{ current_page }" />
    <div class="form-container">
      <div class="form-grouped">
        <div class="div-label">
          Product Platform
          <span class="form_errors" v-if="form_errors.project_type">{{
            form_errors.project_type[0]
          }}</span>
        </div>
        <!-- <label class="label-description"
          >What platform is your product targeted at?</label
        > -->
        <div class="list-wrapper" v-if="isLoadingPlatforms == true">
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 10px"
            class="button-spinner"
          />
        </div>
        <div class="list-wrapper" v-if="isLoadingPlatforms == false">
          <ChipButton
            v-for="(item, index) in platformsData"
            :key="index"
            :text="item.name"
            :buttonIcon="item.img"
            :dashedBorder="true"
            :selected="checkedSelectedItem(item.tag, 'platforms')"
            v-on:click="handleSelectPlatform(item.tag)"
          />
        </div>
      </div>
      <div class="form-grouped">
        <div class="div-label">
          Team Roles
          <span class="form_errors" v-if="form_errors.looking_for">{{
            form_errors.looking_for[0]
          }}</span>
        </div>
        <div class="light-label">
          What team roles do you need? You can always expand these later.
        </div>

        <div class="chip-btn-container" v-if="isLoadingRoles == true">
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 10px"
            class="button-spinner"
          />
        </div>

        <div class="chip-btn-container" v-if="isLoadingRoles == false">
          <ChipButton
            v-for="(item, index) in teamRolesArrData"
            :key="index"
            :text="item.name"
            :dashedBorder="true"
            :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
            :selected="checkedSelectedTeamRole(item.id)"
            v-on:click="handleSelectTeamRole(item.id)"
          />
        </div>
      </div>

      <div class="form-grouped">
        <div class="div-label">
          Tech Stacks
          <span class="form_errors" v-if="form_errors.selectedStacks">{{
            form_errors.selectedStacks[0]
          }}</span>
        </div>
        <div class="light-label">
          Is there a technology you would prefer to have your product built in?
        </div>
        <div class="chip-btn-container">
          <ChipButton
            text="Yes"
            buttonIcon="/img/onboarding/specify_preferences/yes.png"
            v-on:click="handleTechStackPick(1)"
            :dashedBorder="true"
            :selected="form.selectedStacks == 1 ? true : false"
          />
          <ChipButton
            text="No Preference"
            buttonIcon="/img/onboarding/specify_preferences/no.png"
            v-on:click="handleTechStackPick(0)"
            :dashedBorder="true"
            :selected="form.selectedStacks == 0 ? true : false"
          />
        </div>
      </div>

      <div v-if="form.selectedStacks == 1" class="form-grouped">
        <div class="grouped-form">
          <div class="text-box-hint">
            Separate Tech stacks with a comma or tab
          </div>
          <v-flex xs12>
            <v-combobox
              v-model="toolLanguages"
              :items="all_tools"
              item-value="name"
              item-text="name"
              id="backedBy"
              small-chips
              solo
              flat
              label="Tech Stacks"
              color="#979DA5"
              multiple
              class="vue_select"
              :search-input.sync="toolsSearchInput"
              @keypress="handleCommaTwo($event)"
              @change="updateStack"
            ></v-combobox>
            <!--  -->
          </v-flex>
        </div>
      </div>
      <div class="form-grouped">
        <div class="div-label">
          Start Date
          <span class="form_errors" v-if="form_errors.kick_off">{{
            form_errors.kick_off[0]
          }}</span>
        </div>
        <div class="light-label">How soon would you commit to starting?</div>
        <div class="chip-btn-container">
          <ChipButton
            v-for="(item, index) in kickOffPlans"
            :key="index"
            :text="item.name"
            :dashedBorder="true"
            :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
            :selected="item.value == form.kick_off ? true : false"
            v-on:click="handleKickoffSelectStage(item.value)"
          />
        </div>
      </div>

      <div class="form-grouped">
        <div class="div-label">
          How did you hear about us?
          <span class="form_errors" v-if="form_errors.hear_about_us">{{
            form_errors.hear_about_us[0]
          }}</span>
        </div>
        <div class="light-label">
          We would like to know how you heard about Grupa.
        </div>

        <div class="chip-btn-container" v-if="isLoadingHearAboutUs == true">
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 10px"
            class="button-spinner"
          />
        </div>

        <div class="chip-btn-container" v-if="isLoadingHearAboutUs == false">
          <!-- 
              :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
           -->
          <ChipButton
            v-for="(item, index) in getHearAboutUsArray"
            :key="index"
            :text="item.name"
            :dashedBorder="true"
            :selected="checkedSelectedHearAboutUs(item.id)"
            :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
            v-on:click="handleSelectHearAboutUs(item.id)"
          />
        </div>
      </div>
      <div v-if="form.kick_off > 2" class="card-info-container">
        <div class="title-wrapper">
          <div class="info-header">We understand!</div>
          <img src="/img/thumbs-up.svg" style="margin-left: 1px" />
        </div>
        <div class="information-text">
          Join our waiting list and we’ll match you with a team in due time.
        </div>
      </div>
      <div class="button-container">
        <button class="grupa-blue-btn" @click="handleTeamPreference">
          {{
            form.kick_off < 3 ? "Proceed to Build your Team" : "Join Waitlist"
          }}
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
        <!-- <button class="grupa-white-btn">
          {{
            form.kick_off < 3
              ? "No, I’m not in a rush"
              : "No, This is Urgent"
          }}
        </button> -->
      </div>

      <NewHearAboutUsVue
        v-bind="{
          getHearAboutUsArray,
          toggleModal,
          toggleFun,
          finishSaveToList,
        }"
      />
      <!-- </form> -->
    </div>
  </div>
</template>

<script>
import ChipButton from "@/components/chipButton/chipButton";
import MobileInformation from "./MobileInformation.vue";
import { getPlatform, getRoles, getHearAboutUs } from "@/api/client";
import NewHearAboutUsVue from "./../../../../../views/onboarding/dev/linkedin/NewHearAboutUs.vue";

export default {
  props: {
    current_page: {
      required: true,
      type: Number,
    },
    form: {
      required: true,
      type: Object,
    },
    form_errors: {
      required: true,
      type: Object,
    },
    handleTeamPreference: {
      required: true,
      type: Function,
    },
    spinner: { required: true },
    handleTechStackPick: {
      required: true,
      type: Function,
    },
    all_tools: {
      type: Array,
      required: true,
    },
  },
  components: {
    ChipButton,
    MobileInformation,
    NewHearAboutUsVue,
  },

  data() {
    return {
      isProductName: false,
      isLoadingPlatforms: true,
      platformsData: [],
      getHearAboutUsArray: [],
      isLoadingHearAboutUs: true,

      isLoadingHearAboutUs: true,
      toggleModal: false,

      toolsSearchInput: "",

      isLoadingRoles: true,
      teamRolesArrData: [],

      teamRolesArr: [
        {
          name: "Product Designer",
          value: 1,
          id: 1,
          img: "product_designer.png",
        },
        {
          name: "Software Engineer - Frontend",
          value: 2,
          id: 2,
          img: "software_engineer_frontend.png",
        },
        {
          name: "Software Engineer - Backend",
          value: 3,
          id: 3,
          img: "software_engineer_frontend.png",
        },
        {
          name: "Software Engineer - FullStack",
          value: 4,
          id: 4,
          img: "software_engineer_frontend.png",
        },
        {
          name: "QA Engineer",
          value: 5,
          id: 5,
          img: "qa_engineer.png",
        },
        {
          name: "Data Scientist",
          value: 6,
          id: 6,
          img: "data_scientist.png",
        },
        {
          name: "Mobile Engineer - Android",
          value: 7,
          id: 7,
          img: "mobile_app.svg",
        },
        {
          name: "Mobile Engineer - IOS",
          value: 8,
          id: 8,
          img: "mobile_app.svg",
        },
        {
          name: "DevOps Engineer",
          value: 9,
          id: 9,
          img: "devops_engineer.png",
        },
        {
          name: "Product Manager",
          value: 10,
          id: 10,
          img: "product_manager.png",
        },
      ],
      techStacks: [
        {
          name: "Yes",
          value: 1,
          img: "yes.png",
        },
        {
          name: "No Preference",
          value: 2,
          img: "no.png",
        },
      ],
      commitmentLevels: [
        {
          name: "Up to 20hrs a week",
          value: 1,
          img: "time.png",
        },
        {
          name: "Up to 30hrs a week",
          value: 2,
          img: "time.png",
        },
        {
          name: "Up to 40hrs a week",
          value: 3,
          img: "time.png",
        },
      ],
      compensationModels: [
        {
          name: "Cash Only",
          value: 1,
          img: "cash.png",
        },
        {
          name: "A mix of Cash & Equity",
          value: 2,
          img: "cash.png",
        },
        {
          name: "Equity only",
          value: 3,
          img: "cash.png",
        },
        {
          name: `I’m not sure yet`,
          value: 4,
          img: "not_yet_answer.svg",
        },
      ],
      kickOffPlans: [
        {
          name: "ASAP",
          value: 1,
          img: "asap.svg",
        },
        {
          name: "1 week",
          value: 2,
          img: "1_week.svg",
        },
        {
          name: "2 weeks",
          value: 3,
          img: "2_weeks.svg",
        },
        {
          name: "3 weeks",
          value: 4,
          img: "3_weeks.svg",
        },
      ],
      selectedBackedBy: false,
      toolLanguages: [],
      all_tools_name_map: Object.fromEntries(
        this.all_tools.map((x) => [x.name.toLowerCase(), x])
      ),
    };
  },
  created() {
    getHearAboutUs()
      .then((data) => {
        let response = data.data.data;
        this.isLoadingHearAboutUs = false;
        for (var i = 0; i < response.length; i++) {
          this.getHearAboutUsArray.push({
            name: response[i].name,
            status: response[i].status,
            id: response[i].id,
            value: response[i].id,
            img: `list_icon.svg`,
          });
        }
      })
      .catch((error) => {
        // alert('error--')
        this.isLoadingHearAboutUs = false;
        console.log(error);
      });

    getRoles()
      .then((data) => {
        //console.log(data.data.data)
        let response = data.data.data;
        this.isLoadingRoles = false;

        for (var i = 0; i < response.length; i++) {
          this.teamRolesArrData.push({
            name: response[i].name,
            value: response[i].id,
            id: response[i].id,
            img: response[i].img,
          });
        }

        //console.log(this.teamRolesArrData);
        //console.log(this.projectIndustries)
      })
      .catch((error) => {
        this.isLoadingRoles = false;
        console.log(error);
      });

    getPlatform()
      .then((data) => {
        //console.log(data.data.data)
        let response = data.data.data;
        this.isLoadingPlatforms = false;

        for (var i = 0; i < response.length; i++) {
          this.platformsData.push(
            //{ name: response[i].industry_name, tag:response[i].industry_code, img: response[i].industry_svg },
            {
              name: response[i].platforms_name,
              tag: response[i].platforms_code,
              img: `/img/onboarding/${response[i].platforms_svg}`,
              //class: "is_mobile",
              class: response[i].platforms_code,
            }
          );
        }

        //console.log(this.platformsData);
        //console.log(this.projectIndustries)
      })
      .catch((error) => {
        this.isLoadingPlatforms = false;
        console.log(error);
      });
  },
  methods: {
    toggleFun() {
      this.toggleModal = !this.toggleModal;
    },

    finishSaveToList(data) {
      this.getHearAboutUsArray = data;
    },
    handleSelectStage(value) {
      this.form.stage_id = value;
    },

    handleWaitlistPage() {
      let projectId = this.$route.params.id;
      if (projectId) {
        this.$router.push({
          name: "team_evaluation",
          params: { projectId },
        });
      }
    },
    handleSelectTeamRole(value) {
      let selected = this.form.looking_for;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.form.looking_for.push(value);
      } else {
        this.form.looking_for = selected.filter((item) => item !== value);
      }
      this.form_errors.looking_for = [];
    },

    handleSelectHearAboutUs(value) {
      // let selected = this.form.hear_about_us;
      this.form.hear_about_us = value;
      this.form_errors.hear_about_us = [];
      if (value == 6) {
        this.toggleFun();
      }
      // console.log(value);
      /*  const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.form.hear_about_us.push(value);
      } else {
        this.form.hear_about_us = selected.filter((item) => item !== value);
      }
      this.form_errors.hear_about_us = []; */
    },
    handleCommitmentSelectStage(value) {
      this.form.commitmentLevel = value;
      this.form_errors.commitmentLevel = [];
    },
    handleCompensationSelectStage(value) {
      this.form.compensation_id = value;
      this.form_errors.compensation_id = [];
    },
    handleKickoffSelectStage(value) {
      this.form.kick_off = value;
      let is_priority = value < 3 ? 1 : 0;
      let is_waiting = value > 2 ? 1 : 0;
      this.form.is_waiting = is_waiting;
      this.form.is_priority = is_priority;
      this.form_errors.kick_off = [];
    },
    handleCommaTwo(event) {
      if (event.key == ",") {
        event.preventDefault();
        let el = document.getElementById("backedBy");
        var e = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          char: "Tab",
          key: "Tab",
          shiftKey: true,
          keyCode: 9,
        });
        el.dispatchEvent(e);
      } else {
        event;
      }
    },
    updateStack() {
      var stack = [];
      var newToolLanguages = [];
      var seenKeys = [];

      this.toolLanguages.forEach((element) => {
        if (typeof element == "object") {
          stack.push(element.name);
          newToolLanguages.push(element);
          seenKeys.push(element.name.toLowerCase());
          return;
        }

        // For string items, replace with matches
        const match = this.all_tools_name_map[element.toLowerCase()];
        if (!match) {
          return;
        }

        // Replace string with macthing object
        var key = match.name.toLowerCase();
        if (!seenKeys.includes(key)) {
          stack.push(match.name);
          newToolLanguages.push(match);
          seenKeys.push(key);
        }
      });

      this.toolsSearchInput = "";
      this.toolLanguages = newToolLanguages;
      this.form.stack = stack;
    },
    handleSelectPlatform(value) {
      let selected = this.form.project_type;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.form.project_type.push(value);
      } else {
        this.form.project_type = selected.filter((item) => item !== value);
      }
      this.form_errors.project_type = [];
    },
  },
  computed: {
    checkedSelectedTeamRole() {
      return (value) => {
        let selected = this.form.looking_for;
        const foundItem = selected.find((item) => item == value);
        return foundItem ? true : false;
      };
    },

    checkedSelectedHearAboutUs() {
      return (value) => {
        let selected = this.form.hear_about_us;

        return value === selected ? true : false;
      };
    },
    checkedSelectedItem() {
      return (value, type) => {
        let selected =
          type == "platforms" ? this.form.project_type : this.form.industry;
        const foundItem = selected.find((item) => item == value);
        return foundItem ? true : false;
      };
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

div.title {
  text-align: left;
  padding-top: 56px;
  padding-left: 5px;
  font-size: 23px;
  font-weight: bold;
}

.form-container {
  width: 588px;
  padding-top: 20px;
}

.div-label {
  text-align: left;
  font-size: 14px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 145%;
}

.form-control {
  border: 1px solid #e4e5e7;
}

.form-control {
  margin-top: 4px;
  margin-bottom: 0px;
}

.form-grouped {
  margin-top: 48px;
}

.check-product-name {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 8px;
}

.light-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
  text-align: left;
}

.chip-btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.button-container {
  display: flex;
  /* justify-content: flex-end; */
  margin: 48px 0px;
  gap: 8px;
}

.text-box-hint {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}

.grouped-form .v-text-field {
  /* border: 1px solid #e4e5e7; */
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 130%;
}

.card-info-container {
  background: rgba(7, 129, 242, 0.05);
  border-radius: 4px;
  width: 405px;
  text-align: left;
  padding: 24px;
  margin-top: 48px;
}

.title-wrapper {
  display: flex;
}

.info-header {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}

.information-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #53585f;
  margin-top: 12px;
}

.form_errors {
  color: red;
}

.label-description {
  color: #979da5;
}

.list-wrapper {
  display: flex;
  margin-top: 5px;
  gap: 12px;
  width: 588px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .form-container {
    width: 100%;
  }

  .button-container {
    float: none;
  }

  .grupa-blue-btn {
    width: 100%;
  }

  .list-wrapper {
    width: 100%;
  }

  div.title {
    padding-top: 32px;
  }

  .chip-btn-container {
    width: 100%;
  }

  .form-grouped {
    margin-top: 32px;
  }
}
</style>
