<template>
  <div class="left-side">
    <div class="left-side-wrapper">
      <div class="">
        <!-- <img
          :src="`/img/onboarding/${
            current_page == 1
              ? `about-product`
              : current_page == 2
              ? `about-startup`
              : `get-started`
          }.svg`"
          alt=""
        /> -->
        <img src="/img/onboarding/get-started.svg" alt="" />
      </div>
      <div class="stage-text">
        {{
          current_page == 1
            ? aboutProject
            : current_page == 2
            ? aboutStartup
            : getStarted
        }}
      </div>
      <div class="stage-text-below">
        {{
          current_page == 2
            ? startupSubtext
            : current_page == 3
            ? getStartedText
            : ""
        }}
      </div>
      <p class="blue-p dashbboard-mouse" @click="backToDashboard()">
        <img class="dashbboard-mouse" src="/img/onboarding/path.svg" />
        Dashboard
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current_page: {
      required: true,
      type: Number,
    },
  },
  components: {},

  data: () => ({
    aboutProject: `Give us some info about your product. This helps us find you the right
        team and customize an overall experience that suits you best.`,
    aboutStartup: `Great talents want to work with equally great people.`,
    getStarted: `What are you looking out for in the product team you wish to hire and how will you prefer to engage?`,
    startupSubtext: `Tell us about your startup and the amazing team (co-founders and VCs) backing this product idea.`,
    getStartedText: `This helps us narrow down the search and not show you teams that do not fit.`,
  }),

  created() {},
  methods: {
    backToDashboard() {
      this.$router.push({
        name: "client_overview",
      });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.left-side {
  width: 382px;
  position: fixed;
  background: #f7f7f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 80px);
  padding: 0px 40px;
}
.left-side-wrapper p {
  padding-top: 50px;
  text-align: left;
  font-size: 16px;
}
.stage-text-below,
.stage-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 48px;
  text-align: left;
}
.stage-text-below {
  margin-top: 24px;
}
.left-side-wrapper p.blue-p {
  color: #0781f2;
  font-size: 14px;
}
.dashbboard-mouse {
  cursor: pointer;
}
@media (max-width: 1280px) {
  .left-side {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .left-side {
    display: none;
  }
}
</style>
