<template>
  <div>
    <div class="title">
      <h3>Tell us about your product</h3>
    </div>
    <MobileInformation v-bind="{ current_page }" />
    <div class="form">
      <div class="form-grouped">
        <div class="div-label">
          Product name
          <span class="form_errors" v-if="form_errors.project_name">{{
            form_errors.project_name[0]
          }}</span>
        </div>
        <input
          @input="form_errors.project_name = []"
          class="form-control"
          name="project_name"
          v-model="form.project_name"
          placeholder="Enter product name, e.g Uber"
          id="project_name"
          type="text"
        />
      </div>
      <div class="form-grouped">
        <div class="div-label">
          Product description
          <span class="form_errors" v-if="form_errors.overview">{{
            form_errors.overview[0]
          }}</span>
        </div>
        <textarea
          @input="form_errors.overview = []"
          class="form-control"
          v-model="form.overview"
          name="overview"
          maxlength="160"
          v-on:keyup="countCharacters"
          rows="4"
          id="textarea"
          style="height: unset"
          placeholder="Description of product, e.g Taxi's on Demand"
        ></textarea>
        <div
          class="brief_counter"
          style="text-align: left, font-size:10px; height:13px;"
        >
          <div v-show="showCount" style="text-align: left">
            {{ count }} | {{ max_length }}
            <span v-bind:class="{ brief_limit: isLimit }">
              Remaining: {{ remainder }}</span
            >
          </div>
        </div>
      </div>

      <div class="form-grouped">
        <div class="div-label">
          Product Platform
          <span class="form_errors" v-if="form_errors.project_type">{{
            form_errors.project_type[0]
          }}</span>
        </div>
        <label class="label-description"
          >What platform is your product targeted at?</label
        >
        <div class="list-wrapper">
          <ChipButton
            v-for="(item, index) in platforms"
            :key="index"
            :text="item.name"
            :buttonIcon="item.img"
            :dashedBorder="true"
            :selected="checkedSelectedItem(item.tag, 'platforms')"
            v-on:click="handleSelectPlatform(item.tag)"
          />
        </div>
      </div>

      <div class="form-grouped display-inline">
        <div class="div-label">
          Product Industry
          <span class="form_errors" v-if="form_errors.industry">{{
            form_errors.industry[0]
          }}</span>
        </div>
        <label class="label-description"
          >What industries does your product fit in?
        </label>
        <div class="list-wrapper">
          <ChipButton
            v-for="(item, index) in projectIndustries"
            :key="index"
            :text="item.name"
            :dashedBorder="true"
            :buttonIcon="'img/interests/' + item.img"
            :selected="checkedSelectedItem(item.tag)"
            v-on:click="handleSelectIndustry(item.tag)"
          />
        </div>
      </div>
      <div class="button-container">
        <button class="grupa-blue-btn" @click="handleSubmitFormOne">
          Proceed to Startup Info
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ChipButton from "@/components/chipButton/chipButton";
import MobileInformation from "./MobileInformation.vue";

export default {
  props: {
    handleSubmitFormOne: {
      required: true,
      type: Function,
    },
    form: {
      required: true,
      type: Object,
    },
    form_errors: {
      required: true,
      type: Object,
    },
    current_page: {
      required: true,
      type: Number,
    },
  },
  components: {
    ChipButton,
    MobileInformation,
  },

  data() {
    return {
      platforms: [
        {
          name: "Mobile App",
          tag: "mobile",
          img: "/img/onboarding/mobile_app.svg",
          class: "is_mobile",
        },
        {
          name: "Desktop App",
          tag: "mobile_web",
          img: "/img/onboarding/desktop_app.svg",
          class: "is_mobileWeb",
        },
        {
          name: "Data Science",
          tag: "data_science",
          img: "/img/onboarding/data_science.svg",
          class: "is_desktop",
        },

        {
          name: "Web",
          tag: "web",
          img: "/img/onboarding/web.svg",
          class: "is_iots",
        },
        {
          name: "ML/AI",
          tag: "ml_ai",
          img: "/img/onboarding/ml_ai.svg",
          class: "others",
        },
        {
          name: "IOTs",
          tag: "iots",
          img: "/img/onboarding/iots.svg",
          class: "ml_ai",
        },
        {
          name: "Others",
          tag: "others",
          img: "/img/onboarding/others.svg",
          class: "others",
        },
      ],

      projectIndustries: [
        { name: "Finance", tag: "finance", img: "finance.svg" },
        { name: "Travel", tag: "travel", img: "travel.svg" },
        { name: "Security", tag: "security", img: "security.svg" },
        { name: "Education", tag: "education", img: "education.svg" },
        { name: "eCommerce", tag: "eCommerce", img: "eCommerce.svg" },
        { name: "Marketing", tag: "marketing", img: "marketing.svg" },
        { name: "Real Estate", tag: "real_estate", img: "real_estate.svg" },
        { name: "Blockchain", tag: "blockchain", img: "blockchain.svg" },
        { name: "Social Media", tag: "social", img: "social.svg" },
        { name: "Energy", tag: "energy", img: "energy.svg" },
        { name: "HR", tag: "hr", img: "hr.svg" },
        { name: "Hospitality", tag: "hospitality", img: "hospitality.svg" },
        { name: "Gaming", tag: "gaming", img: "gaming.svg" },
        { name: "Legal", tag: "legal", img: "legal.svg" },
        { name: "Others", tag: "others", img: "others.svg" },
      ],
      count: 0,
      showCount: false,
      remainder: 30,
      isLimit: false,
      max_length: 30,
    };
  },
  created() {},
  methods: {
    handleSelectIndustry(value) {
      let selected = this.form.industry;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.form.industry.push(value);
      } else {
        this.form.industry = selected.filter((item) => item !== value);
      }
      this.form_errors.industry = [];
    },
    handleSelectPlatform(value) {
      let selected = this.form.project_type;
      const currentValue = selected.find((item) => item == value);
      if (!currentValue) {
        this.form.project_type.push(value);
      } else {
        this.form.project_type = selected.filter((item) => item !== value);
      }
      this.form_errors.project_type = [];
    },
    countCharacters(e) {
      this.count = e.target.value.length;
      if (this.count >= 1) {
        this.showCount = true;
      } else {
        this.showCount = false;
      }
      if (this.count > 110) {
        this.isLimit = true;
      } else {
        this.isLimit = false;
      }
      this.remainder = this.max_length - this.count;
      // this.remainder =
      //   this.remainder > 0 ? this.max_length - this.count : this.remainder;
    },
  },
  computed: {
    enableBtn() {
      if (this.fieldError == true) {
        return true;
      } else {
        return false;
      }
    },
    checkedSelectedItem() {
      return (value, type) => {
        let selected =
          type == "platforms" ? this.form.project_type : this.form.industry;
        const foundItem = selected.find((item) => item == value);
        return foundItem ? true : false;
      };
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
div.title {
  text-align: left;
  padding-top: 56px;
  padding-left: 5px;
  font-size: 23px;
  font-weight: bold;
}
.form {
  width: 588px;
  padding-top: 40px;
}
.div-label {
  text-align: left;
  font-size: 14px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 145%;
}
.form-control {
  border: 1px solid #e4e5e7;
}
.list-wrapper {
  display: flex;
  margin-top: 5px;
  gap: 12px;
  float: left;
  width: 588px;
  flex-wrap: wrap;
}
.label-description {
  color: #979da5;
  float: left;
}
.display-inline {
  display: inline-block;
  padding-top: 50px;
}
.button-container {
  /*display: flex;*/
  justify-content: flex-end;
  margin: 48px 0px;
  float: left;
}
.form_errors {
  color: red;
}
.brief_counter {
  margin-bottom: 28px;
}
textarea {
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  .form {
    width: 100%;
  }
  .button-container {
    float: none;
  }
  .grupa-blue-btn {
    width: 100%;
  }
  .list-wrapper {
    width: 100%;
  }
  div.title {
    padding-top: 32px;
  }
}
</style>